import React, { useState } from "react";
import styled from "styled-components";
import { FaApple } from "react-icons/fa";
import SmallMusicPlayer from "../components/SmallMusicPlayer";
import withMK from "../hoc/withMK";
import { useSearchParams } from "react-router-dom";

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;

const AppleButton = styled.button`
  width: 80%;
  color: white;
  background-color: #fa2d47;
  padding: 3vw;
  font-weight: 600;
  margin: 1vw 0px;
  border-radius: 3vw;
  border: 0px;
  font-size: 4vw;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const AppleButtonLink = styled.button`
  width: 80%;
  color: #fa2d47;
  background-color: transparent;
  padding: 3vw;
  font-weight: 600;
  margin: 1vw 0px;
  border-radius: 3vw;
  border: 0px;
  font-size: 4vw;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SmallPlayerPage: React.FC = ({ mk }: any) => {
  mk.instance.previewOnly = true;
  const [isInitialScreen, setInitialScreen] = useState(true);
  const [forceAuthentication, setForceAuthentication] = useState(false);
  const [searchParams] = useSearchParams();
  const isForceAuthentication = searchParams.get("reauthenticate") === "true";

  const sendRNMessage = (messageObject: object) => {
    (window as any)?.ReactNativeWebView?.postMessage(
      JSON.stringify(messageObject)
    );
  };

  const handleAuth = async () => {
    try {
      sendRNMessage({ message: "AUTHENTICATION_STARTED" });
      await mk.instance.authorize();
      setInitialScreen(false);
    } catch (e) {
      console.log(e);
      // Show subscribe requiired error here
      sendRNMessage({ message: "NO_ACTIVE_SUBSCRIPTION" });
      alert(
        "in order to use apple music you need to have an active subscription on your account"
      );
    }
  };

  React.useEffect(() => {
    if (mk.instance.isAuthorized && !forceAuthentication) {
      setInitialScreen(false);
      sendRNMessage({ message: "AUTHENTICATION_SUCCEED" });
    } else {
      setInitialScreen(true);
    }
  }, [mk.instance.isAuthorized, forceAuthentication]);

  React.useEffect(() => {
    setForceAuthentication(isForceAuthentication);
  }, [isForceAuthentication]);

  return (
    <div>
      {!isInitialScreen ? (
        <SmallMusicPlayer />
      ) : (
        <Container>
          <AppleButton onClick={handleAuth}>
            <FaApple style={{ marginRight: "1vw" }} /> Authorize
          </AppleButton>
          <AppleButtonLink
            onClick={() => {
              mk.instance.unauthorize();
              setInitialScreen(false);
            }}
          >
            Continue as Guest
          </AppleButtonLink>
        </Container>
      )}
    </div>
  );
};

const bindings = {
  [MusicKit.Events.authorizationStatusDidChange]:
    "authorizationStatusDidChange",
  [MusicKit.Events.eligibleForSubscribeView]: "eligibleForSubscribeView",
};

export default withMK(SmallPlayerPage, bindings);
