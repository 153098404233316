import React, { useState } from "react";
import styled from "styled-components";
import useMK from "../../hooks/useMK";
import { seekToTime } from "../../services/MusicPlayerApi";
import { getTime } from "../../utils/Utils";
interface PlayerTimeProps {
  nowPlayingItem: any;
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 3vw;
  max-width: 80vw;

  .progress-bar,
  .progressBar {
    margin: 4px auto;
    width: 100%;
    height: 4px;
    outline: none;
    border-radius: 2px;
    background: linear-gradient(
        to right,
        $accent_color 0%,
        $accent_color 50%,
        white 50%,
        white 100%
      )
      no-repeat;
    cursor: pointer;
    -webkit-appearance: none;

    &::-webkit-slider-runnable-track {
      box-shadow: none;
      border: none;
      background: transparent;
      -webkit-appearance: none;
    }

    &::-webkit-slider-thumb {
      height: 10px;
      width: 5px;
      border: 0;
      background: $tertiary_color;
      border-radius: 2px;
      -webkit-appearance: none;
    }
  }

  span {
    font-size: 3vw;
    color: $player_secondary_button_color;
  }

  .playbackTime {
    margin-right: 2vw;
  }

  .playbackDuration {
    margin-left: 2vw;
  }
`;

function PlayerTime(props: PlayerTimeProps) {
  const mk = useMK({
    playbackTime: MusicKit.Events.playbackTimeDidChange,
    mediaItem: MusicKit.Events.mediaItemDidChange,
  });

  const [isScrubbing, setIsScrubbing] = useState(false);
  const [scrubbingPosition, setScrubbingPosition] = useState<number | null>(0);

  function timeToPercent(time: number, duration: number) {
    if (duration === 0) {
      return 0; // For some reason would call this
    }

    return Math.floor((time * 100) / duration);
  }

  function getCurrentPlaybackDuration() {
    return getTime(mk.instance.player.currentPlaybackTimeRemaining * 1000);
  }

  function getCurrentPlaybackTime() {
    return getTime(mk.instance.player.currentPlaybackTime * 1000);
  }

  function getCurrentBufferedProgress() {
    // Missing property currentBufferedProgress
    return (mk.instance.player as any).currentBufferedProgress;
  }

  function getDuration() {
    const { nowPlayingItem } = props;

    if (!nowPlayingItem) {
      return 0;
    }

    return mk.instance.isAuthorized
      ? nowPlayingItem.playbackDuration / 1000
      : 30;
  }

  function getPositionValue(e: React.SyntheticEvent<HTMLInputElement>) {
    return ((e.target as HTMLInputElement).value as unknown) as number;
  }

  function onScrub(e: React.SyntheticEvent<HTMLInputElement>) {
    setScrubbingPosition(getPositionValue(e));
  }

  function onStartScrubbing(e: React.SyntheticEvent<HTMLInputElement>) {
    setIsScrubbing(true);
    setScrubbingPosition(getPositionValue(e));
  }

  async function onEndScrubbing(e: React.SyntheticEvent<HTMLInputElement>) {
    await seekToTime(getPositionValue(e));

    setIsScrubbing(false);
    setScrubbingPosition(null);
  }

  function getScrubberValue() {
    if (isScrubbing) {
      return scrubbingPosition;
    }

    if (mk.playbackTime) {
      return mk.playbackTime.currentPlaybackTime;
    }

    return 0;
  }

  function renderProgress() {
    const { playbackTime } = mk;

    const duration = getDuration();
    const percent = playbackTime
      ? timeToPercent(playbackTime.currentPlaybackTime, duration)
      : 0;
    const bufferPercent = playbackTime ? getCurrentBufferedProgress() : 0;

    return (
      <input
        className="progressBar"
        style={{
          background: `linear-gradient(
              to right,
              #fe2851 0%,
              #fe2851 ${percent}%,
              #cccccc ${percent}%,
              #cccccc ${bufferPercent}%,
              #eeeeee ${bufferPercent}%,
              #eeeeee 100%
            ) no-repeat`,
        }}
        type={"range"}
        value={getScrubberValue()}
        onChange={onScrub}
        onMouseDown={onStartScrubbing}
        onTouchStart={onStartScrubbing}
        onMouseUp={onEndScrubbing}
        onTouchEnd={onEndScrubbing}
        min={0}
        max={duration}
        step={1}
      />
    );
  }

  return (
    <Wrapper className="progressBarWrapper">
      <span className="playbackTime">{getCurrentPlaybackTime()}</span>
      {renderProgress()}
      <span className="playbackDuration">{getCurrentPlaybackDuration()}</span>
    </Wrapper>
  );
}

export default PlayerTime;
